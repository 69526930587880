import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthinterceptorService {

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    var userData = JSON.parse(sessionStorage.getItem('userInfo1'));

    if (userData === 'undefined') {
      const cloned = req.clone ({
        headers: req.headers.set('Content-Type', 'application/json')
        .set('userid', userData.username)
        .set('secretuser', userData.cloudsecretuser)
        .set('Authorization', userData.token)

    });
      return next.handle(cloned).pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // if the token is valid
          }
         }, (err: any) => {
          // if the token has expired.
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              localStorage.removeItem('userData');
              this.router.navigate(['/login']);
            }
          }
      }));
  } else {
    const cloned = req.clone ({
      headers: req.headers.set('Content-Type', 'application/json')
      .set('userid', userData.username)
        .set('secretuser', userData.cloudsecretuser)
        .set('Authorization', userData.token)

  });
    return next.handle(cloned).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        
          if(event.body && event.body.status_code === 's_401'){
            localStorage.removeItem('userData');
            this.router.navigate(['/login']);
          }
          // if the token is valid
        }
       }, (err: any) => {
        // if the token has expired.
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // localStorage.removeItem('userData');
            this.router.navigate(['/login']);
          }
        }
    }));

  }

}

  constructor(private router: Router) { }

}
