import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { MyserviceService } from './../myservice.service';

import { MainserviceService } from '.././mainservice.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MyserviceService]
})
export class LoginComponent implements OnInit {
  msg = '';
  registrationForm: FormGroup;
  dummyData: any;
  fieldTextType: boolean;
  constructor(private fb: FormBuilder, private mainserviceService: MainserviceService, private service: MyserviceService, private routes: Router) {
    sessionStorage.setItem("setting_value", '0');
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }


  ngOnInit(): void {
    this.initRegForm();
    console.log("Login Component file");





  }

  initRegForm() {
    this.registrationForm = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", Validators.required]
    });
  }

  loginSubmit() {
    try{
      console.log("user details => ", this.registrationForm.value.username);
      // let url = "/api/v1/usermaster/login"
      this.mainserviceService.loginUserMaster({
        "username": this.registrationForm.value.username,
        "password": this.registrationForm.value.password
      }).subscribe(res => {
        console.log("user login res ======================",res);
        this.respose_catch = res;
        console.log("Response => ", this.respose_catch.status_code);
        if (this.respose_catch.status_code == "s_413") {
          this.msg = "login failure, Username or Password Incorrect";
        } else if (this.respose_catch.status_code == "s_412") {
          localStorage.setItem("userInfo", JSON.stringify(this.respose_catch.data[0]));
          sessionStorage.setItem("userInfo1", JSON.stringify(this.respose_catch.data[0]));
          sessionStorage.setItem("flag", 'true');
          if (this.respose_catch.data[0].usercategoryid == 1) {
            this.routes.navigate(['/birdmeditechdashboard']);
          } else if (this.respose_catch.data[0].usercategoryid == 2) {
            this.routes.navigate(['/customerservicedashboard']);
          } else if (this.respose_catch.data[0].usercategoryid == 3) {
            this.routes.navigate(['/managerdashboard']);
          } else if (this.respose_catch.data[0].usercategoryid == 4) {
            this.routes.navigate(['/fieldengdashboard']);
          } else if (this.respose_catch.data[0].usercategoryid == 5) {
            this.routes.navigate(['/vitalclientdashboard']);
          } else if (this.respose_catch.data[0].usercategoryid == 6) {
            this.routes.navigate(['/vitallocationdashboard']);
          } else if (this.respose_catch.data[0].usercategoryid == 7) {
            this.routes.navigate(['/clientwarmerstatus']);
          } else if (this.respose_catch.data[0].usercategoryid == 8) {
            this.routes.navigate(['/clientwarmerstatus']);
          } else if (this.respose_catch.data[0].usercategoryid == 9) {
            this.routes.navigate(['/clientwarmerstatus']);
          }
        } else {
          this.msg = "Server Problem";
        }
      }), error => {
        console.log(error);
      }
    }catch(err){
      console.log(err);
    }
  }


  logindata: any;
  respose_catch: any;
  loginform = true;
  recoverform = false;
  header_details: any;
  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }
  check(username: string, password: string) {
    console.log(username, password);
    this.logindata = {
      "username": username,
      "password": password
    }
    let url = "/api/v1/adminmaster/login"
    /* this.mainserviceService.loginAdmin(this.logindata).subscribe(res =>{
       console.log(res);
       this.respose_catch = res;
             console.log(this.respose_catch.status_code);
 
             if(this.respose_catch.status_code == "s_413"){
               this.msg = "login failure, Username or Password Incorrect";
             }else if(this.respose_catch.status_code == "s_412"){
               localStorage.setItem("userInfo",JSON.stringify(this.respose_catch.data[0]));
 
               this.routes.navigate(['/starter']);
               }else{
                 this.msg = "Server Problem";
               }
 
     }),error => {
       console.log(error);
     }*/
    /*this.loginservice.post(this.logindata, url).subscribe(res => {
            console.log(res);
            this.respose_catch = res;
            console.log(this.respose_catch.status_code);

            if(this.respose_catch.status_code == "s_413"){
              this.msg = "login failure";
            }else if(this.respose_catch.status_code == "s_412"){
              localStorage.setItem("userInfo",JSON.stringify(this.respose_catch.data[0]));

              this.routes.navigate(['/starter']);
              }else{
                this.msg = "Server Problem";
              }
      }), error => {
        console.log("Error => ",error);
        };
*/
    //
  }
}

/*


    this.routes.navigate(['/starter']);
    //this.SpinnerService.show();
    let url = "/api/v1/adminmaster/login"
    /*this.loginservice.post(this.logindata, url).subscribe(res => {
            console.log(res);
            this.respose_catch = res;
            console.log(this.respose_catch.status_code);
            if(this.respose_catch.status_code == "s_413"){
              this.msg = "login failure";
              //this.SpinnerService.hide();
            }else if(this.respose_catch.status_code == "s_412"){
             // this.SpinnerService.hide();

              this.header_details ={
                'username': this.respose_catch.data[0].adminusername,
                'password':this.respose_catch.data[0].adminpassword,
                "cloudsecretuser":this.respose_catch.data[0].cloudsecretuser,
                "token":this.respose_catch.data[0].token
              };
              //console.log(this.header_details)
              //localStorage.setItem('header', this.header_details);

              //localStorage.setItem();'username', this.respose_catch.data.adminusername,'password', this.respose_catch.data.adminpassword


            }else{
              this.msg = "Server Problem";
            }

          }, error => {
            console.log(error);
        });*/
/*const output = this.service.checkusernameandpassword(uname, p);

if (output == true) {
  this.routes.navigate(['/starter']);
} else {
  this.msg = 'Invalid Username or Password';
}
*/
