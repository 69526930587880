<ngx-spinner></ngx-spinner>

<div class="container-fluid">
  <div>
    <form #addressForm="ngForm" (ngSubmit)="trends_submit()">
      <div class="row fontstyle cardmainstyle shadow-lg bg-white pt-1">
        <div class="col-sm-2">

        </div>

        <div class="col-sm-3">

          <label>Warmer Id:</label>
          <select name="machinename" [(ngModel)]="trendsform.machinename"
            class="browser-default custom-select font_style" (change)="machinechange()">
            <option *ngFor="let machine of machineList" [value]="machine.machineid">
              {{ machine.machinename }}
            </option>
          </select>

          <!-- <select
                name="machinename"
                [(ngModel)] = "trendsform.machinename"
                class="browser-default custom-select font_style"
                (change)="machinechange()"
              >
                <option *ngFor="let machine of machineList" [value]="machine.machineid">
                  {{ machine.machinename }}
                </option>
              </select> -->

        </div>



        <div class="col-sm-3">
          <div class="form-group">
            <label>Parameter :</label>
            <select name="perametername" [(ngModel)]="trendsform.perametername"
              class="browser-default custom-select font_style" (change)="perameterchange()">

              <option *ngFor="let perameter of perameternames" [value]="perameter">
                {{ perameter }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-sm-2">
          <div class="form-group">
            <label>Period:</label>
            <select name="duration" [(ngModel)]="trendsform.duration" class="browser-default custom-select font_style"
              (change)="trends_submit()">
              <option *ngFor="let duration of durations" [value]="duration">
                {{ duration }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-sm-2">
          <button class="btn" (click)="trends_submit()" data-toggle="tooltip" title="Refresh">
            <i class="mdi mdi-autorenew" style="font-size:30px"></i>
          </button>

          <button class="btn" (click)="exportexcel()" data-toggle="tooltip" title="Refresh">
            <i class="mdi mdi-download" style="font-size:30px"></i>
          </button>

        </div>

      </div>
    </form>

    <div class="row mt-4" *ngIf="displayflag">
      <div class="col-sm-12">
        <!-- <div class="card cardmainstyle1"> -->
        <!-- <div class="card-body"> -->
        <!-- Temperature Graph -->
        <!--  ;else temmsg -->
        <span class="text-danger" *ngIf="!temp_show">{{msg}}</span>

        <div *ngIf="spo2_show" class="card-block">
          <!-- <span class="text-danger">{{msg}}</span> -->
          <div class="text-left"><span class="text-info">Spo2 & Pulse Rate Trend</span></div>
          <!-- <p *ngIf="!deltaTData.data || deltaTData.data.length <= 0" class="text-left" style="padding-top: 200px;">No data to display</p> -->
          <div id="spo2chart" style="width: 100%; height: 200px"></div>
          <br>
          <div class="row">
            <div class="col-12">
              <span (click)="tempStatsVisibility = !tempStatsVisibility;" style="cursor: pointer;">
                <i class="fas fa-diagnoses fa-2x"></i>
                <i *ngIf="tempStatsVisibility" class="fas fa-caret-down fa-2x"></i>
                <i *ngIf="!tempStatsVisibility" class="fas fa-caret-right fa-2x"></i>
              </span>
              <div class="card border" *ngIf="tempStatsVisibility">
                <div class="card-header"><span class="text-info">Spo2 and Pulse Rate Trend Statistic</span></div>
                <div class="card-body" style="margin:0px; padding:0px;">
                  <div class="table-responsive table-bordered">
                    <table class="table table-striped mb-0 no-wrap v-middle text-left table_style">
                      <thead class="head_shdow">
                        <tr>
                          <th scope="col">Perameter</th>
                          <th scope="col">Min</th>
                          <th scope="col">Max</th>
                          <th scope="col">Average</th>
                          <th scope="col">Median</th>
                          <th scope="col">Mode</th>
                          <th scope="col">Standard Deviation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let stat of spo2_trend">
                          <td>Spo2</td>
                          <td>{{ stat.min }}</td>
                          <td>{{ stat.max }}</td>
                          <td>{{ stat.average }}</td>
                          <td>{{ stat.median }}</td>
                          <td>{{ stat.mode }}</td>
                          <td>{{ stat.std }}</td>
                        </tr>
                        <tr *ngFor="let stat of pulserate_trend">
                          <td>Pulse Rate</td>
                          <td>{{ stat.min }}</td>
                          <td>{{ stat.max }}</td>
                          <td>{{ stat.average }}</td>
                          <td>{{ stat.median }}</td>
                          <td>{{ stat.mode }}</td>
                          <td>{{ stat.std }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!--weight graph start Here -->
        <div *ngIf="weight_show" class="card-block">
          <span class="text-danger" *ngIf="!weight_show">{{msg}}</span>
          <div class="text-left"><span class="text-info">Weight Trend</span></div>
          <div id="weightgraph" style="width: 100%; height: 200px"></div>
          <br>
          <div class="row">
            <div class="col-sm-12">
              <span (click)="weightStatsVisibility = !weightStatsVisibility;" style="cursor: pointer;">
                <i class="fas fa-diagnoses fa-2x"></i>
                <i *ngIf="weightStatsVisibility" class="fas fa-caret-down fa-2x"></i>
                <i *ngIf="!weightStatsVisibility" class="fas fa-caret-right fa-2x"></i>
              </span>
              <div class="card border" *ngIf="weightStatsVisibility">
                <div class="card-header"><span class="text-info">Weight Rate Trend Statistic</span></div>
                <div class="card-body" style="margin:0px; padding:0px;">
                  <div class="table-responsive table-bordered">
                    <table class="table table-striped mb-0 no-wrap v-middle text-left table_style">
                      <thead class="head_shdow">
                        <tr>
                          <th scope="col">Perameter</th>
                          <th scope="col">Min</th>
                          <th scope="col">Max</th>
                          <th scope="col">Average</th>
                          <th scope="col">Median</th>
                          <th scope="col">Mode</th>
                          <th scope="col">Standard Deviation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let stat of weight_trend">
                          <td>Weight</td>
                          <td>
                            {{ stat.min }}
                          </td>

                          <td>
                            {{ stat.max }}
                          </td>
                          <td>{{ stat.average }}</td>
                          <td>{{ stat.median }}</td>
                          <td>{{ stat.mode }}</td>
                          <td>{{ stat.std }}</td>
                        </tr>


                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Weight Graph end Here-->

        <div *ngIf="temp_show">

          <!-- Temp -->
          <div class="text-left"><span class="text-info">Temprature Trend</span></div>
          <div id="tempchart" style="width: 100%; height: 200px">

          </div>
          <div class="row">
            <div class="col-sm-10">
              <span (click)="tempStatsVisibility = !tempStatsVisibility;" style="cursor: pointer;">
                <i class="fas fa-diagnoses fa-2x"></i>
                <i *ngIf="tempStatsVisibility" class="fas fa-caret-down fa-2x"></i>
                <i *ngIf="!tempStatsVisibility" class="fas fa-caret-right fa-2x"></i>
              </span>
            </div>
            <div class="col-sm-2">
              <table class="float-right" style="width: 100%;">
                <tbody class="text-left">
                  <tr style="margin: 2px;padding: 2px;">
                    <td class="text">Mode</td>
                    <td class="text_infrared">Air</td>
                    <td class="text_infrared">Baby</td>
                    <td class="text_infrared">Manual</td>
                  </tr>
                  <tr>
                    <td class="text">Value</td>
                    <td class="text_infrared">10</td>
                    <td class="text_infrared">20</td>
                    <td class="text_infrared">30</td>
                  </tr>
                  <tr>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br>

          <div class="row" *ngIf="tempStatsVisibility">
            <div class="col-12">
              <div class="card border">
                <div class="card-header"><span class="text-info">Temperature Trend Statistic</span></div>
                <div class="card-body" style="margin:0px; padding:0px;">
                  <div class="table-responsive table-bordered">
                    <table class="table table-striped mb-0 no-wrap v-middle text-left table_style">
                      <thead class="head_shdow">
                        <tr>
                          <th scope="col">Perameter</th>
                          <th scope="col">Min</th>
                          <th scope="col">Max</th>
                          <th scope="col">Average</th>
                          <th scope="col">Median</th>
                          <th scope="col">Mode</th>
                          <th scope="col">Standard Deviation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let stat of temp_stat">
                          <td>Baby Temp</td>
                          <td>{{ stat.min }}</td>
                          <td>{{ stat.max }}</td>
                          <td>{{ stat.average }}</td>
                          <td>{{ stat.median }}</td>
                          <td>{{ stat.mode }}</td>
                          <td>{{ stat.std }}</td>
                        </tr>

                        <tr *ngFor="let stat of set_temp">
                          <td>Set Temp</td>
                          <td>{{ stat.min }}</td>
                          <td>{{ stat.max }}</td>
                          <td>{{ stat.average }}</td>
                          <td>{{ stat.median }}</td>
                          <td>{{ stat.mode }}</td>
                          <td>{{ stat.std }}</td>
                        </tr>

                        <tr *ngFor="let stat of air_temp">
                          <td>Air Temp</td>
                          <td>{{ stat.min }}</td>
                          <td>{{ stat.max }}</td>
                          <td>{{ stat.average }}</td>
                          <td>{{ stat.median }}</td>
                          <td>{{ stat.mode }}</td>
                          <td>{{ stat.std }}</td>
                        </tr>

                        <tr *ngFor="let stat of pheri_temp">
                          <td>Pheripheral Temp</td>
                          <td>{{ stat.min }}</td>
                          <td>{{ stat.max }}</td>
                          <td>{{ stat.average }}</td>
                          <td>{{ stat.median }}</td>
                          <td>{{ stat.mode }}</td>
                          <td>{{ stat.std }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


        <!--delta t end here -->

        <div *ngIf="PI_show" class="card-block">
          <!-- <span class="text-danger">{{msg}}</span> -->
          <div class="text-left"><span class="text-info">PI Trend</span></div>
          <div id="pichart" style="width: 100%; height: 200px">
          </div>
        </div>

        <!--hrchart-->
        <div *ngIf="HR_show" class="card-block">
          <!-- <span class="text-danger">{{msg}}</span> -->
          <div class="text-left"><span class="text-info">HR Trend</span></div>
          <div id="hrchart" style="width: 100%; height: 200px">
          </div>
        </div>
        <!--nbpchart-->

        <!--hrchart-->
        <div *ngIf="NBP_show" class="card-block">
          <!-- <span class="text-danger">{{msg}}</span> -->
          <div class="text-left"><span class="text-info">NBP(Sys/Die) Trend</span></div>
          <div id="nbpchart" style="width: 100%; height: 200px">
          </div>
        </div>

        <!--RRchart-->
        <div *ngIf="RR_show" class="card-block">
          <!-- <span class="text-danger">{{msg}}</span> -->
          <div class="text-left"><span class="text-info">RR Trend</span></div>
          <div id="rrchart" style="width: 100%; height: 200px">
          </div>
        </div>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>

    <div class="row p-2 card mt-4" id="trendsGraphs">
      <div class="col-sm-12 ">

        <div class="row mb-3 ">
          <div class="col-sm-2 bold">
            <strong> June 5,2022 </strong>
          </div>
          <div class="col-sm-10">
            <div class="btn-group float-right" role="group" aria-label="Basic outlined example">
              --{{isFullScreenEnabled}}
              <button type="button" *ngIf="!isFullScreenEnabled" class="btn btn-outline-primary"
                (click)="showFullScreen()">
                <span class="material-icons">fullscreen</span>
              </button>
              <button type="button" *ngIf="isFullScreenEnabled" class="btn btn-outline-primary"
                (click)="showNormalScreen()">
                <span class="material-icons">fullscreen_exit</span>
              </button>
              <button type="button" class="btn btn-outline-primary">4Hr</button>
              <button type="button" class="btn btn-outline-primary">12Hr</button>
              <button type="button" class="btn btn-outline-primary">24Hr</button>
              <button type="button" class="btn btn-outline-primary">Print</button>
            </div>
          </div>


        </div>
        <div class="row ">
          <div class="col-sm-12 pt-2 pb-2 mb-2">
            <div class="row border p-2">
              <div class="col-sm-2  text-center align-self-center">
                <div class="border-bottom">
                  <div style="font-weight: bold;">Spo2 &</div>
                  <div>Pulse Rate Trend</div>
                </div>
                <div class="iconsStyle text-center">
                  <div class="center-items">
                    <span class="material-icons"> arrow_drop_down </span>
                    <span style="font-size: 20px;">80</span>
                  </div>

                  <div class="center-items">
                    <span class="material-icons"> arrow_drop_up</span>
                    <span style="font-size: 20px;"> 110</span>
                  </div>
                  <div style="margin-top:20px;font-size: 48px;font-weight: bold;">
                    98
                  </div>
                </div>
              </div>
              <div class="col-sm-10 border-left">
                <div *ngIf="spo2_show" class="">
                  <!-- <span class="text-danger">{{msg}}</span> -->
                  <!-- <div class="text-left"><span class="text-info">Spo2 & Pulse Rate Trend</span></div> -->
                  <!-- <p *ngIf="!deltaTData.data || deltaTData.data.length <= 0" class="text-left" style="padding-top: 200px;">No data to display</p> -->
                  <div id="spo2chart" style="width: 100%; height: 200px"></div>
                  <br>
                  <div class="row">
                    <div class="col-12">
                      <span (click)="tempStatsVisibility = !tempStatsVisibility;" style="cursor: pointer;">
                        <i class="fas fa-diagnoses fa-2x"></i>
                        <i *ngIf="tempStatsVisibility" class="fas fa-caret-down fa-2x"></i>
                        <i *ngIf="!tempStatsVisibility" class="fas fa-caret-right fa-2x"></i>
                      </span>
                      <div class="card " *ngIf="tempStatsVisibility">
                        <div class="card-header"><span class="text-info">Spo2 and Pulse Rate Trend Statistic</span>
                        </div>
                        <div class="card-body" style="margin:0px; padding:0px;">
                          <div class="table-responsive table-bordered">
                            <table class="table table-striped mb-0 no-wrap v-middle text-left table_style">
                              <thead class="head_shdow">
                                <tr>
                                  <th scope="col">Perameter</th>
                                  <th scope="col">Min</th>
                                  <th scope="col">Max</th>
                                  <th scope="col">Average</th>
                                  <th scope="col">Median</th>
                                  <th scope="col">Mode</th>
                                  <th scope="col">Standard Deviation</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let stat of spo2_trend">
                                  <td>Spo2</td>
                                  <td>{{ stat.min }}</td>
                                  <td>{{ stat.max }}</td>
                                  <td>{{ stat.average }}</td>
                                  <td>{{ stat.median }}</td>
                                  <td>{{ stat.mode }}</td>
                                  <td>{{ stat.std }}</td>
                                </tr>
                                <tr *ngFor="let stat of pulserate_trend">
                                  <td>Pulse Rate</td>
                                  <td>{{ stat.min }}</td>
                                  <td>{{ stat.max }}</td>
                                  <td>{{ stat.average }}</td>
                                  <td>{{ stat.median }}</td>
                                  <td>{{ stat.mode }}</td>
                                  <td>{{ stat.std }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>


        </div>

      </div>

    </div>

  </div>
</div>

<script>
  $(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });
</script>