import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
      },
      {
        path: 'usermaster',
        loadChildren: () => import('./usermaster/usermaster.module').then(m => m.UsermasterModule)
      },
      {
        path: 'mastermanagement',
        loadChildren: () => import('./mastermanagement/mastermanagement.module').then(m => m.MastermanagementModule)
      },
      {
        path: 'birdmeditechprofile',
        loadChildren: () => import('./birdmeditechprofile/birdmeditechprofile.module').then(m => m.BirdmeditechprofileModule)
      },
      {
        path: 'formtest',
        loadChildren: () => import('./formtest/formtest.module').then(m => m.FormtestModule)
      },
      {
        path: 'nursemaster',
        loadChildren: () => import('./nursemaster/nursemaster.module').then(m => m.NursemasterModule)
      },
      {
        path: 'dutydocter',
        loadChildren: () => import('./dutydocter/dutydocter.module').then(m => m.DutydocterModule)
      },
      {
        path: 'notification',
        loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(m => m.HelpModule)
      },
      {
        path: 'machinemaster',
        loadChildren: () => import('./machinemaster/machinemaster.module').then(m => m.MachinemasterModule)
      },
      {
        path: 'clientmaster',
        loadChildren: () => import('./clientmaster/clientmaster.module').then(m => m.ClientmasterModule)
      },
      {
        path: 'site',
        loadChildren: () => import('./site/site.module').then(m => m.SiteModule)
      },
      {
        path: 'alarmmaster',
        loadChildren: () => import('./alrammaster/alrammaster.module').then(m => m.AlrammasterModule)
      },
      {
        path: 'clientdetails',
        loadChildren: () => import('./clientdetails/clientdetails.module').then(m => m.ClientdetailsModule)
      },
      {
        path: 'machinedetails',
        loadChildren: () => import('./machinedetails/machinedetails.module').then(m => m.MachinedetailsModule)
      },
      {
        path: 'complaintstatus',
        loadChildren: () => import('./complaintstatus/complaintstatus.module').then(m => m.ComplaintstatusModule)
      },
      {
        path: 'fieldengineermaster',
        loadChildren: () => import('./fieldengineermaster/fieldengineermaster.module').then(m => m.FieldengineermasterModule)
      },
      {
        path: 'sitesettingdetails',
        loadChildren: () => import('./sitesettingdetails/sitesettingdetails.module').then(m => m.SitesettingdetailsModule)
      },
      {
        path: 'amcstatus',
        loadChildren: () => import('./amcstatus/amcstatus.module').then(m => m.AmcstatusModule)
      },
      {
        path: 'clientwarmerstatus',
        loadChildren: () => import('./clientwarmerstatus/clientwarmerstatus.module').then(m => m.ClientwarmerstatusModule)
      },
      {
        path: 'clientuser',
        loadChildren: () => import('./clientuser/clientuser.module').then(m => m.ClientuserModule)
      },
      {
        path: 'locationadmin',
        loadChildren: () => import('./locationadmin/locationadmin.module').then(m => m.LocationadminModule)
      },
      {
        path: 'controlroommanager',
        loadChildren: () => import('./controlroommanager/controlroommanager.module').then(m => m.ControlroommanagerModule)
      },
      {
        path: 'alarmanalytics',
        loadChildren: () => import('./alarmanalytics/alarmanalytics.module').then(m => m.AlarmanalyticsModule)
      },
      {
        path: 'trends',
        loadChildren: () => import('./trends/trends.module').then(m => m.TrendsModule)
      },
      {
        path: 'trends/:id',
        loadChildren: () => import('./trends/trends.module').then(m => m.TrendsModule)
      },
      {
        path: 'vital-monitors',
        loadChildren: () => import('./trends-back/trends-back.module').then(m => m.TrendsBackModule)
      },
      {
        path: 'vital-monitors/:id',
        loadChildren: () => import('./trends-back/trends-back.module').then(m => m.TrendsBackModule)
      },
      {
        path: 'indusialwrmerstatus',
        loadChildren: () => import('./indusialwarmerstatus/indusialwarmerstatus.module').then(m => m.IndusialwarmerstatusModule)
      },
      {
        path: 'ntflog',
        loadChildren: () => import('./ntflog/ntflog.module').then(m => m.NtflogModule)
      },
      {
        path: 'babymanager',
        loadChildren: () => import('./babymanager/babymanager.module').then(m => m.BabymanagerModule)
      },
      {
        path: 'dutyroasterdetails',
        loadChildren: () => import('./dutyroasterdetails/dutyroasterdetails.module').then(m => m.DutyroasterdetailsModule)
      },
      {
        path: 'medicinemaster',
        loadChildren: () => import('./medicinemaster/medicinemaster.module').then(m => m.MedicinemasterModule)
      },
      {
        path: 'factorysettings',
        loadChildren: () => import('./factorysettings/factorysettings.module').then(m => m.FactorysettingsModule)
      },
      {
        path: 'medicinelist',
        loadChildren: () => import('./medicinelist/medicinelist.module').then(m => m.MedicinelistModule)
      },
      {
        path: 'location',
        loadChildren: () => import('./location/location.module').then(m => m.LocationModule)
      },
      {
        path: 'doctoralocation',
        loadChildren: () => import('./doctoralocation/doctoralocation.module').then(m => m.DoctoralocationModule)
      },
      {
        path: 'dailybabyreport',
        loadChildren: () => import('./dailybabyreport/dailybabyreport.module').then(m => m.DailybabyreportModule)
      },
      {
        path: 'dailybabyreport/:id',
        loadChildren: () => import('./dailybabyreport/dailybabyreport.module').then(m => m.DailybabyreportModule)
      },
      {
        path: 'babyhistory',
        loadChildren: () => import('./babyhistory/babyhistory.module').then(m => m.BabyhistoryModule)
      },
      {
        path: 'dischargereport',
        loadChildren: () => import('./dischargereport/dischargereport.module').then(m => m.DischargereportModule)
      },
      {
        path: 'apgarscore',
        loadChildren: () => import('./apgarscore/apgarscore.module').then(m => m.ApgarscoreModule)
      },
      {
        path: 'birdmeditechdashboard',
        loadChildren: () => import('./birdmeditechdashboard/birdmeditechdashboard.module').then(m => m.BirdmeditechdashboardModule)
      },
      {
        path: 'customerservicedashboard',
        loadChildren: () => import('./customerservicedashboard/customerservicedashboard.module').then(m => m.CustomerservicedashboardModule)
      },
      {
        path: 'managerdashboard',
        loadChildren: () => import('./managerdashboard/managerdashboard.module').then(m => m.ManagerdashboardModule)
      },
      {
        path: 'fieldengdashboard',
        loadChildren: () => import('./fieldengdashboard/fieldengdashboard.module').then(m => m.FieldengdashboardModule)
      },
      {
        path: 'vitalclientdashboard',
        loadChildren: () => import('./vitalclientdashboard/vitalclientdashboard.module').then(m => m.VitalclientdashboardModule)
      },
      {
        path: 'vitallocationdashboard',
        loadChildren: () => import('./vitallocationdashboard/vitallocationdashboard.module').then(m => m.VitallocationdashboardModule)
      },
      {
        path: 'vitalcontroldashboard',
        loadChildren: () => import('./vitalcontroldashboard/vitalcontroldashboard.module').then(m => m.VitalcontroldashboardModule)
      },
      {
        path: 'vitalparameter',
        loadChildren: () => import('./vitalparameter/vitalparameter.module').then(m => m.VitalparameterModule)
      },
      {
        path: 'nerse-intervention',
        loadChildren: () => import('./nerse-intervention/nerse-intervention.module').then(m => m.NerseInterventionModule)
      },
      {
        path: 'fluidbalancechart',
        loadChildren: () => import('./fluidbalancechart/fluidbalancechart.module').then(m => m.FluidbalancechartModule)
      },
      {
        path: 'applog',
        loadChildren: () => import('./applog/applog.module').then(m => m.ApplogModule)
      },
      {
        path: 'doctornotes',
        loadChildren: () => import('./doctornotes/doctornotes.module').then(m => m.DoctornotesModule)
      },
      {
        path: 'testresult',
        loadChildren: () => import('./testresult/testresult.module').then(m => m.TestresultModule)
      },
      {
        path: 'dischargemaster',
        loadChildren: () => import('./dischargemaster/dischargemaster.module').then(m => m.DischargemasterModule)
      },
      {
        path: 'growthchart',
        loadChildren: () => import('./growthchart/growthchart.module').then(m => m.GrowthchartModule)
      },
      {
        path:'nursepanel',
        loadChildren: () => import('./nursepanel/nursepanel.module').then(m => m.NursepanelModule)
      },
      {
        path: 'doctors-panel',
        loadChildren: () => import('./doctors-panel/doctors-panel.module').then(m => m.DoctorsPanelModule)
      },
      {
        path: 'babydischarge',
        loadChildren: () => import('./babyinfo/babydischarge/babydischarge.module').then(m => m.BabydischargeModule)
      },
      {
        path: 'overview',
        loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule)
      },
      {
        path:'clinicalAssesment',
        loadChildren: () => import('./clinicalassesment/clinicalassesment.module').then(m =>m.ClinicalAssesmentModule)
      },
      {
        path: 'assign-machine',
        loadChildren: () => import('./machine-master/assign-machine/assign-machine.module').then(m => m.AssignMachineModule)
      },
      {
        path:'configure',
        loadChildren: () => import('./configure/configure.module').then(m =>m.ConfigureModule)
      },
      {
        path:'eventlogs',
        loadChildren: () => import('./eventtracker/eventlogs/eventlogs.module').then(m =>m.EventlogsModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'login'
  },
  {
    path: 'login',
    component: LoginComponent,
  }
];

export class AppRoutingModule { }

