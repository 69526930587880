<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto ">
    <!-- <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="icon-arrow-left-circle font-18" style="color:rgb(83, 75, 75)"></i>
        </a>
    </li> -->

    <!-- ============================================================== -->
    <!-- Comment -->
    <!-- ============================================================== -->
    <!--<li class="nav-item dropdown" ngbDropdown>
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-message font-18"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title bg-primary text-white">
                        <h4 class="m-b-0 m-t-5">4 New</h4>
                        <span class="font-light">Notifications</span>
                    </div>
                </li>
                <li>
                    <div class="message-center notifications" [perfectScrollbar]="config">

                        <a href="javascript:void(0)" class="message-item" *ngFor="let notification of notifications">
                            <span class="btn btn-circle {{notification.btn}}">
                                <i class="{{notification.icon}}"></i>
                            </span>
                            <span class="mail-contnet">
                                <h5 class="message-title">{{notification.title}}</h5>
                                <span class="mail-desc">{{notification.subject}}</span>
                                <span class="time">{{notification.time}}</span>
                            </span>
                        </a>
                    </div>
                </li>

            </ul>

          </div>
    </li>-->
    <!-- ============================================================== -->
    <!-- End Comment -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Messages -->
    <!-- ============================================================== -->
    <!--<li class="nav-item dropdown" ngbDropdown>
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-email font-18"></i>

        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox" aria-labelledby="2" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-danger"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title text-white bg-danger">
                        <h4 class="m-b-0 m-t-5">5 New</h4>
                        <span class="font-light">Messages</span>
                    </div>
                </li>
                <li>
                    <div class="message-center message-body" [perfectScrollbar]="config">

                        <a href="javascript:void(0)" class="message-item" *ngFor="let mymessage of mymessages">
                            <span class="user-img">
                                <img src="{{mymessage.useravatar}}" alt="user" class="rounded-circle">
                                <span class="profile-status {{mymessage.status}} pull-right"></span>
                            </span>
                            <span class="mail-contnet">
                                <h5 class="message-title">{{mymessage.from}}</h5>
                                <span class="mail-desc">{{mymessage.subject}}</span>
                                <span class="time">{{mymessage.time}}</span>
                            </span>
                        </a>
                    </div>
                </li>
                <li>
                    <a class="nav-link text-center text-muted" href="javascript:void(0);">
                        <b>See all e-Mails</b>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </li>-->
    <!-- ============================================================== -->
    <!-- End Messages -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- mega menu -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- End mega menu -->
    <!-- ============================================================== -->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!--<li class="nav-item d-md-block d-none">
        <form class="app-search">
            <input type="text" class="form-control" placeholder="Search for...">
            <a class="srh-btn">
                <i class="ti-search"></i>
            </a>
        </form>
    </li>-->
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item  dropdown" ngbDropdown>
      <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="mdi mdi-bell ntfset" style="font-size:28px; color:rgb(83, 75, 75)"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right mailbox" aria-labelledby="2" ngbDropdownMenu>
         Showing alert details
      </div>
    </li> -->

    <!-- <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link d-flex align-items-center text-muted waves-effect waves-dark pro-pic"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
           
            <span style="font-size: 40px;" class="material-icons ">account_circle</span>
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                <div class="">
                  
                    <span style="font-size: 40px;" class="material-icons">account_circle</span>
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0" style="text-transform: capitalize;">{{ userData.usercategoryname}}</h4>
                    <p class=" m-b-0" style="text-transform: capitalize;">{{ userData.emailid }}</p>
                </div>
            </div>
            <a class="dropdown-item" routerLink="/birdmeditechprofile">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
            <a class="dropdown-item" href="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
        </div>
    </li> -->

    <li class="nav-item" placement="bottom-right">
        <div ngbDropdown class="d-inline-block">
            <span type="button"
                class="nav-link d-flex align-items-center text-muted waves-effect waves-dark pro-pic material-icons"
                id="dropdownBasic1" style="font-size: 40px;" ngbDropdownToggle>
                account_circle
            </span>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <div class="dropdown-menu-right user-dd">
                    <span class="with-arrow">
                        <span class="bg-primary"></span>
                    </span>
                    <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                        <div class="">
                            <span style="font-size: 40px;" class="material-icons">account_circle</span>
                        </div>
                        <div class="m-l-10">
                            <h4 class="m-b-0" style="text-transform: capitalize;">{{ userData.usercategoryname}}</h4>
                            <p class=" m-b-0" style="text-transform: capitalize;">{{ userData.emailid }}</p>
                        </div>
                    </div>
                    <a class="dropdown-item" routerLink="/birdmeditechprofile">
                        <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
                    <a class="dropdown-item" href="logout()">
                        <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                </div>
            </div>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item d-md-block d-none mt-3">
      <p>{{today | date:'dd-MM-yyyy hh:mm:ss a':'+0530'}}</p>
      <p>&nbsp;06-10-2020</p>
      <p style="margin-top: -14px;">&nbsp;3:10:09 PM</p>

    </li> -->
</ul>


<style>
    .ntfset:hover {
        color: rgb(156, 141, 141);
    }
</style>